// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // adminPasswordPDF:"kanhaiyaadmin123",
  firebase: {
    apiKey: "AIzaSyDxLYCop7kgMKrMCjlnrlJBJWcXB1lByTU",
  authDomain: "chatapp-5e797.firebaseapp.com",
  projectId: "chatapp-5e797",
  storageBucket: "chatapp-5e797.appspot.com",
  messagingSenderId: "409361595207",
  appId: "1:409361595207:web:48ef145edd4291b956ea62"
  },
  production: false,
  apiUrls: {
    // baseUrl: "http://apihrms.kanhaiya.co.in/api/index.php/",
    // mediaURL: 'http://apihrms.kanhaiya.co.in/api/uploads/',
    // authToken: 'kanhaiyahrms',
    baseUrl: "https://apihrms.sparkling.apiitech.co.in/index.php/",
    mediaURL: 'https://apihrms.sparkling.apiitech.co.in/uploads/',
    authToken: 'sparklinghrms',
    appversion:1.0,
    playstoreurl:'',
    apis: {
      getKnowledgeList: 'test-info/index',
      getKnowledgeDetails: 'test-info/show',

      getBlogList:"blog/index",
      getBlogDetails:"blog/show",
      auth:"panel/authenticate",


      Login:'employee/loginUser',
      editEmployee:'employee/editList',
      getEmployee:'employee/getById',

      getByParameterLike:'parameter/getByParameterLike',
      getAllParameter:'parameter/get_all',

      getAllLeave:'leave/get_all',
      saveLeave:'leave/save',
      getleavesbyemployee:'leave/getleavesbyemployee',
      getleavesbyemployeereport:'leave/getleavesbyemployeereport',

      getAllAttendance:'employeeattendancedata/get_all',
      getAttendanceData:'employeeattendancedata/getAttendanceData',
      saveAttendance:'employeeattendancedata/save',
      updateAttendance:'employeeattendancedata/updateAttendanceData',
      saveattendancefromapp:'employeeattendancedata/saveattendancefromapp',
      updateAttendancefromapp:'employeeattendancedata/updateAttendancefromapp',
      checkintime:'employeeattendancedata/checkintime',
      checkouttime:'employeeattendancedata/checkouttime',
      getAttendanceDataByEmployee:'employeeattendancedata/getAttendanceDataByEmployee',


      getDistinctMonthByEmployee:'payslip/getDistinctMonthByEmployee',
      getDistinctYearByEmployee:'payslip/getDistinctYearByEmployee',
      getSalaryByMonth:'payslip/getSalaryByMonth',
      getSalaryByEmployee:'payslip/getSalaryByEmployee',

      getTasksByEmployee:'tasks/getTasksByEmployee',
      getTasksById:'tasks/getById',
      editTask:'tasks/editList',

      getComplainByEmployee:'complain/getByEmployeeId',
      getComplainById:'complain/getById',
      saveComplain:'complain/save',

      getSuggestionByEmployee:'suggestion/getByEmployeeId',
      getSuggestionById:'suggestion/getById',
      saveSuggestion:'suggestion/save',

      getAllPolicy:'policy/get_all',
      getPolicyById:'policy/getById',

      getAllNotice:'notice/get_all',
      getNoticeById:'notice/getById',

      getAllAdvance:'advance/get_all',

      getWorkingDaysOfMonth:'employeeattendancedata/getWorkingDaysOfMonth',
      getWorkingDaysOfYear:'employeeattendancedata/getWorkingDaysOfYear',
      

      getMonthAttendanceReport:'employeeattendancedata/getMonthAttendanceReport',
      getYearAttendanceReport:'employeeattendancedata/getYearAttendanceReport',
      getPresentDays:'employeeattendancedata/getPresentDays',

      getAllHolidays:'holiday/get_all',

      getNetEarning:'employeepayroll/getNetEarning',
      getNetDeduction:'employeepayroll/getNetDeduction',

      getlocationbyname:'location/getByName',
      getLocation:'location/getById',
      getParameterAll:'parameter/get_all',

    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
