import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  image: any="";
  name:any='';


  constructor(
    private router: Router,
    private service: AuthService
    ) { 
       this.name=localStorage.getItem('name') 
       this.image=localStorage.getItem('image') 
    }

  ngOnInit() { }
  redirect(path) {
    this.router.navigate([path]);

  }

}
