import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { KnowledgeCenterComponent } from './components/knowledge-center/knowledge-center.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  {
    path: 'home-page',
    loadChildren: () => import('./pages/home-page/home-page.module').then( m => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./pages/filter/filter.module').then( m => m.FilterPageModule)
  },
  {
    path: 'blogs',
    loadChildren: () => import('./pages/blogs/blogs.module').then( m => m.BlogsPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'accredition',
    loadChildren: () => import('./pages/accredition/accredition.module').then( m => m.AccreditionPageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.changepasswordPageModule)
  },
  {
    path: 'viewprofile',
    loadChildren: () => import('./pages/viewprofile/viewprofile.module').then( m => m.viewprofilePageModule)
  },
  {
    path: 'menu',
    component:MenuComponent
  },
  {
    path: 'knowledge-center/:id',
    component:KnowledgeCenterComponent
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'dashboard',  
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },  
  
  // {
  //   path: 'tasks',
  //   loadChildren: () => import('./pages/dashboard/components/tasks/tasks.component').then( m => m.tasksComponent)
  // },
  // {
  //   path: 'payslips',
  //   loadChildren: () => import('./pages/dashboard/components/payslips/payslips.component').then( m => m.payslipsComponent)
  // },
  {
    path: 'viewtasks',
    loadChildren: () => import('./pages/dashboard/components/viewtasks/viewtasks.component').then( m => m.viewtasksComponent)
  },


  
    {
    path: '',
    redirectTo: '/splash',
    pathMatch: 'full'
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],

  exports: [RouterModule]
})
export class AppRoutingModule {}
